import * as THREE from "three"
import anime from 'animejs/lib/anime.es.js';

import { SVGLoader } from "three/examples/jsm/Addons";
import { Coin } from "./coin";
import { Timer } from "three/examples/jsm/misc/Timer";
import { TumblingCollisionObject } from "./collision_object";

const WORLD_ROTATION_SPEED = 0.00125;
const SATELLITE_DEFAULT_HEIGHT = 11;

class AnimationState
{
	// Serialisable state (so we can use pushstack in the browser)

	idx: number;
	// Globe pivot - i.e looking face on or from the
	// 'polar orbit perspective'
	globe_pivot: number;
	// Camera z
	camera_y: number;
	camera_z: number;
	// World rotation - from the point of view of the stationery satellite
	// i.e. pole-to-pole
	polar_speed: number;
	// The y axis shift of the globe
	globe_shift: number;

	// Coins shown?
	coins: boolean;

	// Start falling?
	gravity: boolean;

	// Space junk?
	space_junk: boolean;

	constructor({
		idx = 0, 
		globe_pivot = 90, 
		camera_y = 4, 
		camera_z = 12, 
		polar_speed = WORLD_ROTATION_SPEED * 16, 
		globe_shift=-3,

		coins = false,
		gravity = false,
		space_junk = false,
		} = {} )
	{
		this.idx = idx;
		this.globe_pivot = globe_pivot;
		this.camera_y = camera_y;
		this.camera_z = camera_z;
		this.polar_speed = polar_speed;
		this.globe_shift = globe_shift;
		this.coins = coins;
		this.gravity = gravity;
		this.space_junk = space_junk;
	}
}

class IntroAnimation{
	camera: THREE.Camera;
	scene: THREE.Scene;
	renderer: THREE.Renderer;

	globe_sphere: THREE.Mesh;
	previous_animation_state : AnimationState;

	satellite_group : THREE.Group;

	coins_in_play : Array<Coin>;
	coin_timer : Timer;
	seconds_since_last_coin : number;

	space_junk_in_play : Array<TumblingCollisionObject>;
	space_junk_timer: Timer;
	seconds_since_last_junk_object: number;

	onWindowResize() {
		const canvas = this.renderer.domElement;
		this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
		this.camera.updateProjectionMatrix();
		this.renderer.setSize( canvas.clientWidth, canvas.clientHeight);
		console.log("Resized, aspect: " + this.camera.aspect);
	}

	constructor(previous_animation_state : AnimationState){
		this.coins_in_play = [];
		this.space_junk_in_play = [];
		this.space_junk_timer = new Timer();
		this.seconds_since_last_junk_object = 4.0;
		this.coin_timer = new Timer();
		this.seconds_since_last_coin = 4.0;
		this.previous_animation_state = previous_animation_state;
		this.scene = new THREE.Scene();
		this.globe_sphere = new THREE.Mesh();
		// Get the document element of id 'animation'
		const canvas = document.getElementById('animation') as HTMLCanvasElement;
		// Create a camera with the size of the canvas
		this.camera = new THREE.PerspectiveCamera(
			70, 
			canvas.clientWidth / canvas.clientHeight, 
			0.1, 
			1000
		);
		// Set the camera position
		this.camera.position.z = this.previous_animation_state.camera_z;
		// Create a renderer with the canvas
		this.renderer = new THREE.WebGLRenderer( { antialias: true } );
		// this.scene.background = new THREE.Color( 0xb0b0b0 );
    	const helper = new THREE.GridHelper( 160, 10, 0x8d8d8d, 0xc1c1c1 );
    	helper.rotation.x = Math.PI / 2;
    	// this.scene.add( helper );
    	canvas.appendChild(this.renderer.domElement);;
    	// Set the size of the renderer to the size of canvas
    	// console.log("canvas.clientWidth: " + canvas.clientWidth + " canvas.clientHeight: " + canvas.clientHeight);
    	this.renderer.setSize( canvas.clientWidth, canvas.clientHeight );
    	// Build our animated globe_sphere
    	const texture_loader = new THREE.TextureLoader();
    	const texture = texture_loader.load("world.png", (texture) => {
    		console.log("World loaded");
    		texture.colorSpace = THREE.SRGBColorSpace
    		const geometry = new THREE.SphereGeometry( 10, 32, 32 );
    		const material = new THREE.MeshBasicMaterial({map : texture});
    		const mesh = new THREE.Mesh(geometry, material);
    		this.globe_sphere = mesh;
    		this.globe_sphere.position.y = -1;
    		this.scene.add(mesh);
    	});
 
    	const satellite_loader = new SVGLoader();
    	this.satellite_group = new THREE.Group();
    	satellite_loader.load("satellite.svg", ( data ) => {
    		this.satellite_group.scale.multiplyScalar( 0.005 );
			this.satellite_group.position.x = 1.8;
			this.satellite_group.position.y = SATELLITE_DEFAULT_HEIGHT;
			this.satellite_group.position.z = 0;
    		this.satellite_group.rotateOnAxis(new THREE.Vector3(0,0,1), THREE.MathUtils.degToRad(135));
    		let renderOrder = 0;
			for ( const path of data.paths ) {
				const fillColor = path.userData.style.fill;
				if ( fillColor !== 'none' ) {
					const material = new THREE.MeshBasicMaterial( {
						color: new THREE.Color().setStyle( fillColor ),
						opacity: path.userData.style.fillOpacity,
						transparent: true,
						side: THREE.DoubleSide,
						depthWrite: false,
					} );
					const shapes = SVGLoader.createShapes( path );
					for ( const shape of shapes ) {
						const geometry = new THREE.ShapeGeometry( shape );
						const mesh = new THREE.Mesh( geometry, material );
						mesh.renderOrder = renderOrder ++;
						this.satellite_group.add( mesh );
					}
				}
				this.scene.add(this.satellite_group);
			}
    	});

	    this.onWindowResize();
	    window.onresize = this.onWindowResize.bind(this);
	    window.requestAnimationFrame((millis_since_last) => this.animate(millis_since_last))
	    document.getElementById("guide-text").innerHTML = text_values[this.previous_animation_state.idx];
	}

	animate(millis_since_last : number){
		const step = () =>{
			if(current_animation_state.idx !== this.previous_animation_state.idx){
				console.log(`Animation state changed ${this.previous_animation_state.idx} -> ${current_animation_state.idx}`);
				this.previous_animation_state = current_animation_state;
			}

			this.camera.position.y = current_animation_state.camera_y;
			this.camera.position.z = current_animation_state.camera_z;
			if(current_animation_state.globe_pivot != 0){
				this.globe_sphere.rotation.z = THREE.MathUtils.degToRad(current_animation_state.globe_pivot);
			}
			// Handle the case when we've jumped back after a bit
			// if(current_animation_state.globe_pivot == 0 && this.globe_sphere.rotation.z != 0){
			// 	this.globe_sphere.rotation.z = 0;
			// }
			this.globe_sphere.position.y = current_animation_state.globe_shift;
			if(this.globe_sphere){
				// this.globe_sphere.rotateOnWorldAxis(new THREE.Vector3(0,1,0), 0.001);
				this.globe_sphere.rotateY(WORLD_ROTATION_SPEED);
				this.renderer.render(this.scene, this.camera);
			}
			else{
				console.log("Globe not loaded");
			}

			// Logic to determine if we show this or not
			// below
			const coins_still_in_play : Array<Coin> = [];
			for(const coin of this.coins_in_play){
				coin.animate(1);
				if(coin.claim_or_delete(this.satellite_group.position.y)){
					this.scene.remove(coin.pivot);
				}
				else{
					coins_still_in_play.push(coin);
				}
			}
			this.coins_in_play = coins_still_in_play;

			// Coins
			if(current_animation_state.coins){
				this.coin_timer.update();
				this.seconds_since_last_coin += this.coin_timer.getDelta();
				if(this.seconds_since_last_coin >= 1){
					this.seconds_since_last_coin = 0;
					const new_coin = new Coin(this.scene, () => {});
					new_coin.add_to_scene(30,10);
					console.log("Coin added");
					this.coins_in_play.push(new_coin);
				}
			}
			else{
				// No coins - remove them all
				for(const coin of this.coins_in_play){
					this.scene.remove(coin.pivot);
				}
				this.coins_in_play = [];
			}

			// Gravity (learn to control it)
			if(current_animation_state.gravity){
				this.satellite_group.position.y -= 0.01;
			}
			else{
				// Stay at our default height
				this.satellite_group.position.y = SATELLITE_DEFAULT_HEIGHT;
			}

			// Space junk
			const objects_still_in_play: Array<TumblingCollisionObject> = [];
			for(const object of this.space_junk_in_play){
				object.animate();
				if(object.out_of_play()){
					object.remove_from_scene();
				}
				else{
					objects_still_in_play.push(object);
				}
			}
			this.space_junk_in_play = objects_still_in_play;

			if(current_animation_state.space_junk){
				this.space_junk_timer.update();
				this.seconds_since_last_junk_object += this.space_junk_timer.getDelta();
				if(this.seconds_since_last_junk_object >= 2){
					this.seconds_since_last_junk_object = 0;
					const new_object = new TumblingCollisionObject(
						this.scene,
						this.satellite_group.position,
						10,12,1
					);
					this.space_junk_in_play.push(new_object);
					console.log("Added object");
				}
			}
			else{
				for(const object of this.space_junk_in_play){
					object.remove_from_scene();
				}
				this.space_junk_in_play = [];
			}

			requestAnimationFrame(step);
		}
		step();
	}

	handle_key_down = (event : KeyboardEvent) => {
		// Space bar = 32
		if(event.keyCode == 32){
			if(current_animation_state.gravity){
				if(this.satellite_group.position.y < SATELLITE_DEFAULT_HEIGHT + 0.1){
					this.satellite_group.position.y += 0.5;
				}
			}
		}
	}

	handle_touch = (event) => {
		if(current_animation_state.gravity){
			if(this.satellite_group.position.y < SATELLITE_DEFAULT_HEIGHT + 0.1){
				this.satellite_group.position.y += 0.5;
			}
			event.preventDefault();
		}
	}
}

const slide_deck : Array<AnimationState> = [
	new AnimationState({
		idx : 0,
		globe_pivot: 0,
		camera_y: 0,
		camera_z: 18,
		globe_shift: 0,
		polar_speed: 0,
	}),
	new AnimationState({idx: 1}),
	new AnimationState({idx: 2, coins: true}),
	new AnimationState({idx: 3, coins: false, gravity: true}),
	new AnimationState({idx: 4, coins: false, gravity: false, space_junk: true}),
]

const text_values : Array<String> = [
	`Take control of an earth-observation satellite in Low Earth Orbit.<br />
Earn money!<br />
But be careful - space is getting crowded<br/> You'll need to avoid others, and gravity`,
	`Meet Flappy!<br />
Your very own Low Earth Orbit Earth Observation Satellite...`,
	`Earn coins by taking pictures of the Earth!<br />`,
	`Don't get dragged down!<br />Press the space bar or tap your screen to gain altitude`,
	`But space is getting crowded<br />Remember to avoid other satellites and space debris`,
]

let current_animation_state = slide_deck[0];
history.pushState(current_animation_state, "", "/");

function advance_animation_state(button_event : Event){
	// Blergh - sorry
	if(document.getElementById("next-button").innerHTML == "Go!"){
		document.location.href = "/game.html";
	}
	if(current_animation_state.idx < 1){
		// history.pushState(current_animation_state, "", "/");
		// current_animation_state = slide_deck[current_animation_state.idx + 1];
		const next_animation_state = slide_deck[current_animation_state.idx + 1];
		anime({
			targets: current_animation_state,
			globe_pivot: next_animation_state.globe_pivot,
			camera_y: next_animation_state.camera_y,
			camera_z: next_animation_state.camera_z,
			polar_speed: next_animation_state.polar_speed,
			globe_shift: next_animation_state.globe_shift,
			duration: 1000,
			update: () => {
				console.log("Updating");
				// console.log(current_animation_state);
			},
			easing: "easeInSine",
			complete: () => {
				current_animation_state = next_animation_state;
				history.pushState(current_animation_state, "", "/");
				document.getElementById("guide-text").innerHTML = text_values[current_animation_state.idx];
			},
		})
		console.log("Advancing")
		console.log(current_animation_state);
	}
	else if(current_animation_state.idx >= 1 && current_animation_state.idx < slide_deck.length - 1){
		// console.log("Advancing")
		current_animation_state = slide_deck[current_animation_state.idx + 1];
		history.pushState(current_animation_state, "", "/");
		document.getElementById("guide-text").innerHTML = text_values[current_animation_state.idx];

	}
	// 'Blur' (i.e. remove focus) of the button that was pressed so that
	// touching or hitting space won't advance through the animation at this point (the one where we introduce the controls)
	if(current_animation_state.idx == 3){
		event.target.blur();
	}
	if(current_animation_state.idx == 4){
		document.getElementById("next-button").innerHTML = "Go!";
		document.getElementById("skip-button").style.visibility = "hidden";
	}

}
window.addEventListener("popstate", (event) => {
	if(event.state){
		current_animation_state = event.state;
		document.getElementById("guide-text").innerHTML = text_values[current_animation_state.idx];
		console.log("Popped state");
		console.log(current_animation_state);
	}
	else{
		// Default browser back behaviour
		history.go(-1);
	}
});

const animation = new IntroAnimation(current_animation_state);
document.getElementById("next-button").onclick = advance_animation_state;
document.addEventListener("keydown", animation.handle_key_down);
document.addEventListener("touchstart", animation.handle_touch);
